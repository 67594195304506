import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
// import useBrowserMode from '../shared/hooks/useBrowserMode'

const MiCuenta = props => {
	// const { isBrowser } = useBrowserMode();
	// isBrowser && navigate('mi-cuenta/datos-personales')

	useEffect(() => {
		navigate('mi-cuenta/datos-personales')
	}, [])
	return <></>
}

export default MiCuenta
